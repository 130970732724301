import { fetcher } from 'src/api/fetch';
import { UserProfileResponse } from '../types';


const requestGetUserProfile = async (language: string) => {
  const response = await fetcher<UserProfileResponse>(`/user/profile/`, {
    credentials: 'include',
    language,
  });

  return response;
};

export default requestGetUserProfile;
