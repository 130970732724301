import { useServices } from "..";

const useAuth = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useLang');
  }

  return services.auth;
};

export default useAuth;
