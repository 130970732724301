import { fetcher } from 'src/api/fetch';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';

const requestPostUserLogout = async () => {
  const response = await fetcher(`/user/logout/`, {
    method: 'POST',
    credentials: 'include',
  });

  return getErrorsFromResponse(response);
};

export default requestPostUserLogout;
