import { ApiErrorShape } from 'src/api/types';
import { LATEST_API_VERSION, apiRoot, metaXChain } from 'src/data/constants';

interface RequestParams {
  login: string;
  password: string;
}

export interface SessionInfo {
  expires: number;
  user_id: string;
}

interface RequestResult {
  errors?: ApiErrorShape[];
  data?: SessionInfo;
}

const requestPostLogin = async (params: RequestParams): Promise<RequestResult> => {
  const resp = await fetch(`${apiRoot}/user/login/`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-chain': metaXChain,
      'x-version': LATEST_API_VERSION,
    },
    body: JSON.stringify(params),
  });

  if (!resp.ok) {
    const { errors } = await resp.json();

    if (resp.status === 400 || resp.status === 429 || resp.status === 401) {
      return { errors };
    }

    return { errors: [{ error_code: 5000, description: 'Unexpected error' }] };
  } else {
    const data = await resp.json();

    return { data };
  }
};

export default requestPostLogin;
