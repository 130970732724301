import cn from 'classnames';
import transformColorPropsToSBS from '../StylishBox/helpers/transformColorPropsToSBS';
import StylishBox, { StylishBoxElementProps } from '../StylishBox/StylishBox';
import { StylishBoxColorProps, StylishBoxResponsiveProps, StylishBoxStyles } from '../StylishBox/types';
import { useTheme } from '../theme';
import getFontSize from './helpers/getFontSize';
import { IconFontName } from './IconFontName';

export interface IconFontProps {
  name: IconFontName;
  /**
   * fontSize of icon by px or 'inherit'
   * @default 'inherit'
   * */
  size?: StylishBoxResponsiveProps<`${number}px` | 'inherit'>;
  /**
   * Colors of the theme.base.color or 'inherit' as default
   * @default 'inherit'
   * */
  color?: StylishBoxColorProps;
  elementProps?: StylishBoxElementProps<'i'>;
  sbs?: StylishBoxStyles;
  dataMarker?: string;
  dataTestId?: string;
  className?: string;
}

const IconFont = (props: IconFontProps) => {
  const {
    name,
    size,
    color,
    elementProps,
    sbs,
    dataMarker,
    dataTestId,
    className,
  } = props;

  const theme = useTheme();
  const defaultSizeAndColor = 'inherit';
  const resultSBS: StylishBoxStyles = {
    color: transformColorPropsToSBS(color, theme.base.color, defaultSizeAndColor),
    fontSize: getFontSize(size, defaultSizeAndColor),
    ...sbs,
  };

  return (
    <StylishBox
      element='i'
      sbs={resultSBS}
      elementProps={elementProps}
      dataMarker={dataMarker}
      dataTestId={dataTestId}
      className={cn(`icon-${name}`, className)}
    />
  );
};

export default IconFont;
