import { useMemo } from "react";
import { Category } from "src/api/types";

const useCategoriesService = (initCategories: Category[]) => {
  const memoizedCategories = useMemo(
    () => initCategories,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return memoizedCategories;
};

export default useCategoriesService;
