import { baseTheme } from 'src/ui-kit/theme/skins/base';

export const typographyJSStyles = {
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '36px',
    * * lineHeight: '40px',
    * * color: baseTheme.base.color.dark,
   */
  h1: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '36px',
    lineHeight: '40px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '24px',
    * * lineHeight: '28px',
    * * color: baseTheme.base.color.dark,
   */
  h2: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '18px',
    * * lineHeight: '24px',
    * * color: baseTheme.base.color.dark,
   */
  h3: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '16px',
    * * lineHeight: '22px',
    * * color: baseTheme.base.color.dark,
   */
  h4: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '16px',
    * * lineHeight: '21px',
    * * color: baseTheme.base.color.dark,
   */
  h5: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color: baseTheme.base.color.dark,
   */
  h6: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color: baseTheme.base.color.dark,
   */
  h9: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color: baseTheme.base.color.dark,
   */
  text: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color: baseTheme.base.color.dark,
   */
  smallAccentedText: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '21px',
    * * color: '#9F9F9F',
   */
  notAccentedText: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#9F9F9F',
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '12px',
    * * lineHeight: '18px',
    * * color: '#9F9F9F',
   */
  notAccentedH9: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#9F9F9F',
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '14px',
   */
  btn: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 600,
    * * fontStyle: 'normal',
    * * fontSize: '28px',
    * * lineHeight: '34px',
    * * color: baseTheme.base.color.dark,
   */

  h1Mobile: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '28px',
    lineHeight: '34px',
    color: baseTheme.base.color.dark,
  },
  /**
    * * fontFamily: baseTheme.base.fontFamily.normal,
    * * fontWeight: 400,
    * * fontStyle: 'normal',
    * * fontSize: '14px',
    * * lineHeight: '16px',
    * * color: '#ff5252',
   */
  error: {
    fontFamily: baseTheme.base.fontFamily.normal,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: baseTheme.base.color.error,
  },
};

function transformProps(prop) {
  let result = prop;
  for (let i = 0; i < prop.length; i++) {
    if (prop[i] === prop[i].toUpperCase()) {
      result = `${result.substring(0, i)}-${prop[i].toLowerCase()}${result.substring(i+1)}`;
    }
  }
  return result;
}

function createCSSStyles(obj) {
  const result = {};
  for (const key in obj) {
    result[key] = '';
    for (const prop in obj[key]) {
      result[key] += `${transformProps(prop)}:${obj[key][prop]};`;
    }
  }
  return result;
}

const typography = createCSSStyles(typographyJSStyles) as typeof typographyJSStyles;

export default typography;
