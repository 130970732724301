import { ErrorResponse, fetcher, FetcherParams, unexpectedErrorResponse } from 'src/api/fetch';
import { getInstance } from './ApiCacheClient';
import { getCacheKeyWithPrefix } from './helpers';

const TTL = Number(process.env.CACHE_TTL) || 60;

interface CacheClient {
  get(cacheKey: string): Promise<string | null>;
  set(cacheKey: string, data: string, ttl: number): Promise<void>;
}

interface Config {
  url: string;
  cacheKey: string;
  fetchOptions?: FetcherParams;
  ttlSeconds?: number;
}

interface FetchedResultSuccess<ResponseData> {
  data: ResponseData;
  source: 'fetch';
  status: 'success';
}

interface CachedResult<ResponseData> {
  data: ResponseData;
  source: 'cache';
  status: 'success';
}

type fetchWithMemcachedResult<T> = FetchedResultSuccess<T> | CachedResult<T> | ErrorResponse;

export async function fetchWithMemcached<ResponseData>(
  config: Config,
): Promise<fetchWithMemcachedResult<ResponseData>> {
  const { cacheKey: initialCacheKey, url, fetchOptions, ttlSeconds = TTL } = config;
  const cacheKey = getCacheKeyWithPrefix(initialCacheKey);

  const cacheClient: CacheClient = getInstance();

  let cachedResponse: string | null = null;

  try {
    cachedResponse = await cacheClient.get(cacheKey);
  } catch (error) {
    console.error(`ERROR RETRIEVING ${cacheKey}: ${error.message}`);
  }

  if (cachedResponse) {
    return {
      source: 'cache',
      data: JSON.parse(cachedResponse),
      status: 'success',
    };
  }

  const response = await fetcher<ResponseData>(url, fetchOptions);

  const responseExists = response.status === 'success';

  if (responseExists) {
    try {
      await cacheClient.set(cacheKey, JSON.stringify(response.data), ttlSeconds);

      return {
        source: 'fetch',
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      console.error(`ERROR SETTING ${cacheKey}: ${error.message}`);
    }
  }

  return unexpectedErrorResponse;
}
