import { LATEST_API_VERSION, apiRoot, metaXChain } from 'src/data/constants';
import { ApiErrorShape } from './types';

export interface FetcherParams extends RequestInit {
  language?: string;
}

export interface ErrorResponse {
  status: 'fail';
  errors: ApiErrorShape[];
  retryAfter?: number;
}

interface SuccessResponse<T> {
  status: 'success';
  data: T;
  redirectLocation?: string | null;
  retryAfter?: number;
}

export type FetcherResponse<T> = ErrorResponse | SuccessResponse<T> | never

export const unexpectedErrorResponse: ErrorResponse = {
  status: 'fail',
  errors: [{ error_code: 5000, description: 'Unexpected error' }],
};

export async function fetcher<T>(endpoint: RequestInfo, {
  language,
  headers,
  ...restParams
}: FetcherParams = {}, defaultValue = {} as T): Promise<FetcherResponse<T>> {
  const basePath = apiRoot as string;
  try {
    const result = await fetch(basePath + endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'x-chain': metaXChain,
        'x-version': LATEST_API_VERSION,
        ...(language && { 'Accept-Language': language }),
        ...headers,
      },
      ...restParams,
    });
    const responseText = await result.text();

    const redirectLocation = result.headers.get('content-location');
    const retryAfterFromHeader = result.headers.get('retry-after');
    const retryAfter = retryAfterFromHeader ? Number(retryAfterFromHeader) : 0;

    //because some endpoints returns expected empty response
    if (!responseText && result.ok) {
      return {
        status: 'success',
        data: defaultValue,
        redirectLocation,
        retryAfter,
      };
    }

    const response = JSON.parse(responseText);

    if (response.errors) {

      if (result.status === 400 || result.status === 429) {
        return {
          status: 'fail',
          errors: response.errors,
          retryAfter,
        };
      }

      return unexpectedErrorResponse;
    }

    return {
      status: 'success',
      data: response,
      redirectLocation,
      retryAfter,
    };
  } catch (e) {
    return unexpectedErrorResponse;
  }
}
