import { useServices } from "..";

const useLang = (): string => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useLang');
  }

  return services.language;
};

export default useLang;
