import { fetchWithMemcached } from 'src/utils/ApiCacheClient/fetchWithMemcached';
import { COUNTRY } from '../data/constants';
import { TranslationDictionary } from 'src/services/localize/useLocalizeService';


function fetchTranslations(language: string) {
  return fetchWithMemcached<TranslationDictionary>({
    url: `/translations/?project=zakaz${COUNTRY}`,
    cacheKey: `translations-${language}`,
    fetchOptions: {
      language,
    },
  });
}

export default fetchTranslations;
