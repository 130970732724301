import { useCallback, useEffect, useState } from "react";
import requestGetCheckLogin from "./api/requestGetCheckLogin";
import requestPostConfirmSignup from "./api/requestPostConfirmSignup";
import requestPostLogin from "./api/requestPostLogin";
import requestPostUserLogout from "./api/requestPostLogout";

const useAuthLogic = (language: string) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginIsChecked, setLoginIsChecked] = useState(false);

  const checkIsLoggedIn = useCallback(async () => {
    setIsFetching(true);
    const isLoggedIn = await requestGetCheckLogin(language);
    setIsLoggedIn(isLoggedIn);
    setIsFetching(false);
    setLoginIsChecked(true);
  }, [language]);

  useEffect(() => {
    if (!loginIsChecked) {
      checkIsLoggedIn();
    }
  }, [checkIsLoggedIn, loginIsChecked]);

  const logout = useCallback(async () => {
    setIsFetching(true);
    const res = await requestPostUserLogout();

    if (res?.errors) {
      return;
    }

    setIsFetching(false);
    setIsLoggedIn(false);
  }, []);

  const login = useCallback(async (login, password) => {
    const { data, errors } = await requestPostLogin({
      login,
      password,
    });

    if (data) {
      setIsLoggedIn(true);
    }

    return errors;
  }, []);

  const register = useCallback(async (phone, otp) => {
    const { data, errors } = await requestPostConfirmSignup({
      language,
      phone,
      otp,
    });

    if (data) {
      setIsLoggedIn(true);
    }

    return errors;
  }, [language]);

  return {
    checkIsLoggedIn,
    isLoggedIn,
    loginIsChecked,
    isFetching,
    logout,
    login,
    register,
  };
};

export default useAuthLogic;
