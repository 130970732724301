import { useServices } from "..";

export const useLocalize = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useLocalize');
  }

  return services.localize;
};


export default useLocalize;
