const { promisify } = require('util');

let instance;

//needed to handle unexpected memcached errors, such as loss of connection to the server
const safePromisify = (fn) => {
  return async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      return null;
    }
  };
};

export const getInstance = () => {
  if (instance) {
    return instance;
  }

  const cacheAddress = process.env.CACHE_ADDRESS;

  if (!cacheAddress || typeof window !== "undefined") {
    instance = {
      get: () => Promise.resolve(null),
      set: () => Promise.resolve(null),
      flush: () => Promise.resolve(null),
    };
    return instance;
  }

  if (cacheAddress && typeof window === 'undefined') {
    const Memcached = require("memcached");
    const connector = new Memcached(
      cacheAddress,
      {
        failures: 10,
        retry: 200,
        retries: 2,
        timeout: 1000,
        minTimeout: 1,
        maxTimeout: 1000,
        reconnect: 100,
        maxValue: 6291456,
      },
    );

    connector.on('issue', details => {
      console.log(`MCACHE ISSUE: ${details.messages.join(', ')}`);
    });

    instance = {
      get: safePromisify(promisify(connector.get).bind(connector)),
      set: safePromisify(promisify(connector.set).bind(connector)),
      flush: safePromisify(promisify(connector.flush).bind(connector)),
    };

    return instance;
  }
};
