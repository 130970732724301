import * as Sentry from '@sentry/nextjs';
import { useMemo } from 'react';
import fetchTranslations from 'src/api/fetchTranslations';

export interface LocalizeFunc {
  (key: string): string;
}

export interface TranslationDictionary {
  [key: string]: string;
}

interface Params {
  translations: TranslationDictionary;
}

export const getLocalize = (translations: TranslationDictionary) => (key: string) => {
  const translation = translations[key];

  if (translation) {
    return translation;
  }

  Sentry.captureException(
    new Error(`Missing translation for key '${key}'.`), {
      extra: {
        translations: JSON.stringify(translations),
      },
    });

  return key;
};

export const getLocalizeOnServer = async (lang: string): Promise<LocalizeFunc> => {
  const translationsResponse = await fetchTranslations(lang);

  if (translationsResponse.status === 'fail') {
    throw new Error('Translations are empty.');
  }

  const translations = translationsResponse.data;

  return getLocalize(translations);
};

export function useLocalizeService(params: Params) {
  const { translations } = params;

  const localize = useMemo(() => {
    /*
      We receive translations on the server in the _app.getInitialProps function.
      On the first render, the props contain this value, it goes into the closure
      and is never overwritten.
      After going through the client routing, there are no translations in the props
      (after calling getInitialProps on the client), we don’t need them
      since we use translations from the closure
    */
    if (!translations) {
      throw new Error('Translations are empty.');
    }

    return getLocalize(translations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 return localize;
}
