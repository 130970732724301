import { useCallback, useEffect, useState } from "react";
import requestGetUserProfile from "./ajax/requestGetUserProfile";
import requestPostUserProfile from "./ajax/requestPostUserProfile";
import { UserProfile, UserProfileResponse } from "./types";
import { getClientSideUselessEmails, getIsEmailEditable, getServerSideEmails } from "./utils/dataConverters";

const useUserProfileService = (language: string, isLoggedIn: boolean) => {
  const [userProfile, setUserProfile] = useState<UserProfileResponse | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const canEditEmail = userProfile ? getIsEmailEditable(userProfile.emails) : true;

  const getUserProfile = useCallback(async () => {
    setIsFetching(true);
    const response = await requestGetUserProfile(language);

    if (response.status === 'success') {
      setUserProfile(response.data);
    }
    setIsFetching(false);
  }, [language]);

  useEffect(() => {
    if (isLoggedIn && !userProfile && !isFetching) {
      getUserProfile();
    }
  }, [isLoggedIn, userProfile, isFetching, getUserProfile]);

  const saveUserProfile = async (profileInfo: Partial<UserProfile>) => {
    setIsFetching(true);

    const response = await requestPostUserProfile(
      {
        name: profileInfo?.name || '',
        surname: profileInfo?.surname || '',
        emails: getServerSideEmails(
          profileInfo?.email || '',
          getClientSideUselessEmails(userProfile?.emails),
          !canEditEmail,
        ),
        subscribed_to_marketing: userProfile?.subscribed_to_marketing,
      },
      language,
    );

    if(!response?.errors) {
      getUserProfile();
    }

    setIsFetching(false);
  };

  const clearProfile = () => {
    setUserProfile(null);
  };

  return {
    getUserProfile,
    saveUserProfile,
    clearProfile,
    userProfile,
    isFetching,
  };
};

export default useUserProfileService;
