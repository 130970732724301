import { fetcher } from 'src/api/fetch';
import { getErrorsFromResponse } from 'src/utils/api/getErrorsFromResponse';
import { UserDataPostRequestBody } from '../types';

const requestPostUserProfile = async (
  userData: UserDataPostRequestBody,
  language: string,
) => {
  const response = await fetcher<void>(`/user/profile/personal_info/`, {
    method: 'POST',
    credentials: 'include',
    language,
    body: JSON.stringify(userData),
  });

  return getErrorsFromResponse(response);
};

export default requestPostUserProfile;
