import { UserEmail } from "../types";

export function getClientSideUselessEmails(emails: UserEmail[] = []) {
  return emails.map(e => e.email).filter(e => !!e);
}

export function getClientSideUserEmail(emails: UserEmail[] = []) {
  if (!emails || emails.length === 0) return '';

  return emails[0].email;
}

export function getServerSideEmails(
  currentEmail: string, storedEmails: string[] = [], isLogin = false,
) {
  if (isLogin) {
    return storedEmails;
  }

  const [, ...restEmails] = storedEmails;

  if (currentEmail === '') {
    return [...restEmails];
  }

  return [currentEmail, ...restEmails];
}

export function getIsEmailEditable(emails: UserEmail[]) {
  if (emails.length === 0) return true;

  return emails[0].is_editable;
}
