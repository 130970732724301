import { fetcher } from 'src/api/fetch';
import { ApiErrorShape } from 'src/api/types';
import { SessionInfo } from './requestPostLogin';

interface RequestParams {
  language: string;
  phone: string;
  otp?: string;
}

interface RequestResult {
  errors?: ApiErrorShape[];
  data?: SessionInfo;
}

const requestPostConfirmSignup = async (params: RequestParams): Promise<RequestResult>=> {
  const { language, ...rest } = params;

  const response = await fetcher<SessionInfo>(`/user/confirm_signup/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(rest),
    language,
  });

  return response;
};

export default requestPostConfirmSignup;
