import { fetcher } from 'src/api/fetch';
import { SessionInfo } from './requestPostLogin';

const requestGetCheckLogin = async (language: string): Promise<boolean> => {
  const url = `/user/login/`;

  const response = await fetcher<SessionInfo>(url, {
    credentials: 'include',
    language,
  });

  return response.status === 'success';
};

export default requestGetCheckLogin;
