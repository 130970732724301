import { IconFontProps } from '../IconFont';

const getFontSize = (sizeProps: IconFontProps['size'], defaultFontSize: `${number}px` | 'inherit') => {
  if (!sizeProps) {
    return defaultFontSize;
  }

  if (typeof sizeProps === 'string') {
    return sizeProps;
  }

  return {
    ...sizeProps,
    general: sizeProps.general || defaultFontSize,
  };
};

export default getFontSize;
