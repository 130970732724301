import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';

const useLangService = (initLang: string) => {
  const [language] = useState(initLang);

  if (!language) {
    Sentry.captureException(
      new Error(`The language is missing when using useLangService`),
      {
        extra: {
          translations: JSON.stringify({ language }),
        },
      },
    );
  }

  return language;
};

export default useLangService;
